<template>
  <div class="container">
       <div class="container-body">
     <div :class="ClientWidth_number > 850 ?'body-box1' : 'body-box-phone'" style="background:#eff3f8;">
         <el-row :gutter="20">
            <el-col :span="cardWidth">
                  <el-card shadow="hover"  style="margin-top:15px;">
                    <el-row>
                            <el-col :span="24">
                                <div style="height:200px; margin:0 auto;">
                                   <img src="../../assets/lock1.jpg" alt="" style="width:100%;height:100%;">
                                  </div>  
                            </el-col>
                      </el-row> 
                       <el-row>
                            <el-col :span="24">
                                   <el-row>
                                      <el-col :span="24" >
                                          <div style="width:300px; margin:0 auto; ">
                                                <h3 style="margin:0 25%;">网页防篡改</h3> 
                                          </div>  
                                          <div style=" margin:0 auto;" class="content">
                                                          <p style="margin-top:5px;">- 禁止系统文件被非法篡改</p> 
                                                          <p style="margin-top:5px;">- 禁止非法提权操作</p> 
                                                          <p style="margin-top:5px;">- 禁止恶意代码执行</p> 
                                                          <p style="margin-top:5px;">- 禁止加载没有数字签名的驱动</p> 
                                                          <p style="margin-top:5px;" >- 优化系统配置及服务</p> 
                                                          <p style="margin-top:5px;">- 修复操作系统及软件漏洞</p> 
                                                      </div>  
                                      </el-col>
                                  </el-row> 
                            </el-col>
                      </el-row>
                  </el-card>
            </el-col>
               <el-col :span="cardWidth">
                  <el-card shadow="hover"  style="margin-top:15px;">
                   <el-row>
                            <el-col :span="24">
                                <div style="height:200px; margin:0 auto; margin-top:20px;">
                                   <img src="../../assets/lock2.png" alt="" style="width:100%;height:100%;">
                                  </div>  
                            </el-col>
                      </el-row> 
                       <el-row>
                         <el-row>
                              <el-col :span="24">
                                  <div style="height:185px; margin:0 auto;">
                                       <div style="width:266px;margin:0 auto;">
                                        <h3 style="margin:0 25%;">网站漏洞防护</h3> 
                                       </div>
                                        <el-col :span="24">
                                            <div style=" margin:0 auto;">
                                                  <p style="margin-top:5px;" class="content">网站漏洞防护采用基于特征码和基于行为的双检测技术对网站木马进行扫描和清除</p> 
                                              </div>  
                                        </el-col>
                                  </div>  
                              </el-col>
                          </el-row> 
                      </el-row>
                  </el-card>
            </el-col>
            <el-col :span="cardWidth">
                  <el-card shadow="hover" style="margin-top:15px;">
                    <el-row>
                            <el-col :span="24">
                                <div style="height:200px;margin:0 auto;">
                                   <img src="../../assets/lock3.jpg" alt="" style="width:100%;height:100%;">
                                  </div>  
                            </el-col>
                      </el-row> 
                       <el-row>
                            <el-col :span="24">
                                  <div style="height:202px;margin:0 auto;">
                                       <div style="width:266px;margin:0 auto;">
                                        <h3 style="margin:0 25%;">网页防止劫持</h3> 
                                       </div>
                                        <el-col :span="24">
                                            <div style=" margin:0 auto;">
                                                  <p style="margin-top:5px;" class="content">网页防止劫持就是遵守SSL协议的服务器数字证书，为浏览器到服务器 所之间搭建一条加密通道建立SSL连接保证数据在传输过程中不被窃取和篡 改，确保机密信息的保密性，完整性和可靠性</p> 
                                              </div>  
                                        </el-col>
                                  </div>  
                              </el-col>
                      </el-row>
                  </el-card>
            </el-col>
        </el-row>
    </div>
    </div>
    <div class="container-body">
       <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
        <el-row>
            <el-col :span="6">
                <div class="grid-content bg-purple title" style="height:70px;width:140px;">
                    <h3>网页防篡改</h3>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>赛尔安全防篡改系统通过内核加固操系统，提高系统自身的安全性和抗攻击能力， 保护系统核心文件，支持windows、linux操作系统；通过禁止操作系统中无用的 服务，提高系统安全性，同时减低系统资源占用。</p>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>事前拦截：事先自动禁止对web服务器保护目录下文件或文件夹的各种篡改，包括 篡改文件时间，各种属性，文件名，文件内容等，从而抢占先机，预先自动阻止黑 客非法篡改网页，杜绝和减小黑客攻击篡改网站的可能性。</p>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>事后恢复：即使黑客通过未知的方式篡改了网页，也能在毫秒级发现，并传唤备份 立即自动给予恢复，同时记录相关日志，发送邮件报警、手机短信报警、日志等通 知管理员。</p>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple title" style="height:50px;width:128px;">
                    <h3>技术优势</h3>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                  <div class="grid-content bg-purple content">
                    <p>1.支持多种保护模式，防止静态和动态网站内容被非法篡改；采用内核驱动 级文件保护，通过核心内嵌技术，支持大规模连续篡改攻击防护。实时检 测与防护，完全杜绝被篡改内容被外界浏览。支持断线状态下篡改防护。</p>
                </div>
            </el-col>
            <el-col :span="24">
                  <div class="grid-content bg-purple content">
                    <p>2.访问策略可以灵活配置、功能模块可以灵活组合、文件类型可以按需添加。 全中文操作界面、导航式安装提示、内置安全模板，大大缩短部署时间。</p>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>3.支持的多种操作系统: Windows 2000、Windows 2003、windows 7、 windows 2008 32bit/64bit Linux(CentOS、Debian、Ubuntu) FreeBSD、MacOS WebSphere</p>
                </div>
            </el-col>
            <el-col :span="24">
                  <div class="grid-content bg-purple content"  style="padding-bottom:3vw;">
                    <p>4.部署灵活，可采用串联、旁路安装方式进行部署，管理方便，减轻管理员 配置工作及技术水平要求，降低管理复杂度。</p>
                </div>
            </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xxx: "\u3000\u3000",
      cardWidth:8,
      ClientWidth_number:document.documentElement.clientWidth
    };
  },
  created() {
    this.getClientWidth();
    this.cardWidth = document.documentElement.clientWidth > 1200 ? 8 : (document.documentElement.clientWidth< 500 ? 24 : 12)
  },
  methods: {
    getClientWidth() {
      addEventListener('resize',()=>{
      this. ClientWidth_number = document.documentElement.clientWidth
       this.cardWidth = document.documentElement.clientWidth > 1200 ? 8 : (document.documentElement.clientWidth< 500 ? 24 : 12)
      })
    },
  },
};
</script>

<style lang='less' scoped>
@baseWidth: 1980;
.pxfix(@w) {
  width: (@w / @baseWidth) * 100vw;
}
 .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .container-body {
    .pxfix(1980);
    min-height: 512px;
    margin: 0 auto;
    background: #eff3f8;
    // margin-top: 20px;
    // display: flex;
    // flex: 1;
    .title{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: normal;
        font-family: "microsoft yahei";
        color: #555;
    }
    .content{
      margin: 0 1rem;
      color: #555;
      font-size:0.8rem; 
      line-height: 25px; 
      font:'microsoft yahei';
       }
    .body-box1{
    .pxfix(1400);
     margin:1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
    }
    .body-box{
    .pxfix(1280);
     margin:1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
    }
    .body-box-phone{
        background: #fff;
        margin:1.5rem auto;
    }
     .body-box:hover{
     box-shadow: 0 0  10px #888888;
    }
  }
  .footer {
    line-height: 50px;
    background: #2f3034;
    width: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
</style>
